@font-face {
	font-family: "benton-sans-regular";
	src: url("/static/font/benton-sans/benton-sans-regular.woff2") format("woff2"),
		url("/static/font/benton-sans/benton-sans-regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "benton-sans-bold";
	src: url("/static/font/benton-sans/benton-sans-bold.woff2") format("woff2"),
		url("/static/font/benton-sans/benton-sans-bold.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
