@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.auth-footer {
	@extend %text-bold;
	color: white;
	text-align: center;
	font-size: 10px;

	&__item {
		&:last-of-type {
			@extend %font-normal;
			color: white;
		}
	}
}
