:root {
	--color-primary-100: #99a2bd;
	--color-primary-200: #66749c;
	--color-primary-300: #33457b;
	--color-primary-400: #012281;
	--color-primary-500: #00175a;
	--color-primary-600: #001349;
	--color-primary-700: #000d3d;
	--color-primary-800: #000f3b;

	--color-secondary-100: #d9e9f8;
	--color-secondary-200: #99c5ec;
	--color-secondary-300: #66a9e2;
	--color-secondary-400: #0095e6;
	--color-secondary-500: #006fcf;
	--color-secondary-600: #003d92;
	--color-secondary-700: #003176;
	--color-secondary-800: #002860;

	--color-black: #000000;
	--color-gray-300: #f5f6f7;
	--color-gray-400: #ecedee;
	--color-gray-500: #b1b8c3;
	--color-gray-600: #647286;
	--color-gray-700: #465266;

	// timer
	--color-background-timer: var(--color-secondary-500);
	--color-text-timer: white;

	// footer
	--color-background-footer: var(--color-gray-600);
	--color-text-footer: white;

	// badge inclus
	--color-background-badge-inclus: var(--color-secondary-500);
	--color-text-badge-inclus: white;

	// push notification
	--color-background-push-notification: var(--color-secondary-600);

	// active color
	--color-active: var(--color-secondary-500);

	// payment banner
	--payment-description-background-color: #d9e9f8;
	--payment-description-instalments-background-color: #006fcf;

	// input-label
	--input-label-active-color: var(--color-secondary-500);

	// picture shadow
	--picture-shadow-layer: rgba(0, 15, 59, 0.5);
}
